import { render, staticRenderFns } from "./guardPayment.vue?vue&type=template&id=7196ca42&scoped=true&"
import script from "./guardPayment.vue?vue&type=script&lang=js&"
export * from "./guardPayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7196ca42",
  null
  
)

export default component.exports